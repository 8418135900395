import React, { useEffect, useState, useCallback, useMemo } from "react";
import * as ethers from "ethers";
import moment from "moment";
import { RiLoader5Fill } from "react-icons/ri";
import cx from "classnames";
import { today, yesterday} from '../helpers'

import {
  yaxisFormatterNumber,
  yaxisFormatterPercent,
  yaxisFormatter,
  tooltipLabelFormatter,
  tooltipLabelFormatterUnits,
  tooltipFormatter,
  tooltipFormatterNumber,
  tooltipFormatterPercent,
  formatNumber,
  CHART_HEIGHT,
  YAXIS_WIDTH,
  COLORS,
} from "../helpers";

import {
  LineChart,
  BarChart,
  Line,
  Bar,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  ReferenceLine,
  Area,
  AreaChart,
  ComposedChart,
  Cell,
  PieChart,
  Pie,
} from "recharts";

import ChartWrapper from "../components/ChartWrapper";
import VolumeChart from "../components/VolumeChart";
import FeesChart from "../components/FeesChart";
import GenericChart from "../components/GenericChart";
import PoolAmountChart from "../components/PoolAmountChart";
import DateRangeSelect from '../components/DateRange/DateRangeSelect'

import {
  useVolumeData,
  useTotalVolumeFromServer,
  useVolumeDataFromServer,
  useFeesData,
  useQlpData,
  useAumPerformanceData,
  useCoingeckoPrices,
  useQlpPerformanceData,
  useTradersData,
  useSwapSources,
  useFundingRateData,
  useUsersData,
  useLastSubgraphBlock,
  useLastBlock,
  NOW_TS,
  FIRST_DATE_TS,
  FROM_DATE_TS,
} from "../dataProvider";

function Polygon(props) {
  const DEFAULT_GROUP_PERIOD = 86400;
  const [groupPeriod, setGroupPeriod] = useState(DEFAULT_GROUP_PERIOD);
  const [dataRange, setDataRange] = useState({ fromValue: FROM_DATE_TS, toValue: null });

  const { mode } = props;

  const from = dataRange.fromValue ? dataRange.fromValue : FIRST_DATE_TS;
  const to =  dataRange.toValue ? +dataRange.toValue : NOW_TS;

  const params = { from, to, groupPeriod };
  const totalParams = { from: yesterday(), to: today(), groupPeriod } 
  const firstDayParams = { from: FIRST_DATE_TS, to: NOW_TS, groupPeriod } 
  const [fundingRateData, fundingRateLoading] = useFundingRateData(params);
  // const [volumeData, volumeLoading] = useVolumeDataFromServer(params);
  const [volumeData, , volumeLoading] = useVolumeData(params);
  const [totalVolumeData, totalVolume ,totalVolumeLoading] = useVolumeData(firstDayParams);
  //const [totalVolume] = useTotalVolumeFromServer();
  const totalVolumeDelta = useMemo(() => {
    if (!totalVolumeData || totalVolumeData.length == 0) {
      return null;
    }
    return totalVolumeData[totalVolumeData.length - 1].all;
  }, [totalVolumeData]);

  const [feesData, feesLoading] = useFeesData(params);
  const [totalFeesData, totalFeesLoading] = useFeesData(firstDayParams);
  const [totalFees, totalFeesDelta] = useMemo(() => {
    if (!totalFeesData) {
      return [];
    }
    const total = totalFeesData[totalFeesData.length - 1]?.cumulative;
    const delta = total - totalFeesData[totalFeesData.length - 2]?.cumulative;
    return [total, delta];
  }, [totalFeesData]);

  const [qlpData, qlpLoading] = useQlpData(params);
  const [totalQlpData, ] = useQlpData(totalParams);
  const [totalAum, totalAumDelta] = useMemo(() => {
    if (!totalQlpData) {
      return [];
    }
    const total = totalQlpData[totalQlpData.length - 1]?.aum;
    const delta = total - totalQlpData[totalQlpData.length - 2]?.aum;
    return [total, delta];
  }, [totalQlpData]);

  const [aumPerformanceData, aumPerformanceLoading] =
    useAumPerformanceData(params);

  const [qlpPerformanceData, qlpPerformanceLoading] = useQlpPerformanceData(
    qlpData,
    feesData,
    params
  );

  const [tradersData, tradersLoading] = useTradersData(params);
  const [totalTradersData, totalTradersLoading] = useTradersData(totalParams);
  const [openInterest, openInterestDelta] = useMemo(() => {
    if (!totalTradersData) {
      return [];
    }
    const total = totalTradersData.data[totalTradersData.data.length - 1]?.openInterest;
    const delta =
      total - totalTradersData.data[totalTradersData.data.length - 2]?.openInterest;
    return [total, delta];
  }, [totalTradersData]);
  const [swapSources, swapSourcesLoading] = useSwapSources(params);
  const swapSourcesKeys = Object.keys(
    (swapSources || []).reduce((memo, el) => {
      Object.keys(el).forEach((key) => {
        if (key === "all" || key === "timestamp") return;
        memo[key] = true;
      });
      return memo;
    }, {})
  );

  const [usersData, usersLoading] = useUsersData(params);
  const [totalUsersData, totalUsersLoading] = useUsersData(totalParams);
  const [totalUsers, totalUsersDelta] = useMemo(() => {
    if (!totalUsersData) {
      return [null, null];
    }
    const total = totalUsersData[totalUsersData.length - 1]?.uniqueCountCumulative;
    const prevTotal = totalUsersData[totalUsersData.length - 2]?.uniqueCountCumulative;
    const delta = total && prevTotal ? total - prevTotal : null;
    return [total, delta];
  }, [totalUsersData]);

  const [lastSubgraphBlock] = useLastSubgraphBlock();
  const [lastBlock] = useLastBlock();

  const isObsolete =
    lastSubgraphBlock &&
    lastBlock &&
    lastBlock.timestamp - lastSubgraphBlock.timestamp > 3600;

  const [isExperiment, setIsExperiment] = useState(false);
  useEffect(() => {
    setIsExperiment(window.localStorage.getItem("experiment"));
  }, [setIsExperiment]);

  const showForm = true;
  const onDateRangeChange = (dates) => {
    const [start, end] = dates;
    setDataRange({ fromValue: start && parseInt(start.getTime()/1000), toValue: end && parseInt(end.getTime()/1000) })
  };

  const dateRangeOptions = [{
    label: "Last Month",
    id: 1,
    isDefault: true
  }, {
    label: "Last 2 Months",
    id: 2,
  }, {
    label: "Last 3 Months",
    id: 3,
  }, {
    label: "All time",
    id: 4
  }]
  return (
    <div className="Home">
      <h1 style={{color:"#EBECF2"}}>Analytics / Polygon</h1>
      {lastSubgraphBlock && lastBlock && (
        <p
          className={cx("page-description", { warning: isObsolete })}
          style={{ marginTop: "-1rem" }}
        >
          {isObsolete && "Data is obsolete. "}
          Updated {moment(lastSubgraphBlock.timestamp * 1000).fromNow()}
          &nbsp;at block{" "}
          <a
            target="_blank"
            href={`https://zkevm.polygonscan.com/block/${lastSubgraphBlock.number}`}
            style={{color: ""}} rel="noreferrer"
          >
            {lastSubgraphBlock.number}
          </a>
        </p>
      )}
      {showForm && (
        <div className="form">
          <DateRangeSelect options={dateRangeOptions} startDate={new Date(dataRange.fromValue*1000)} endDate={new Date(dataRange.toValue*1000)} onChange={onDateRangeChange} />
        </div>
      )}
      <div className="chart-grid">
        <div className="chart-cell stats">
          {totalVolume ? (
            <>
              <div className="total-stat-label">Total Volume</div>
              <div className="total-stat-value">
                {formatNumber(totalVolume, { currency: true })}
                {totalVolumeDelta && (
                  <span
                    className="total-stat-delta plus"
                    title="Change since previous day"
                  >
                    +
                    {formatNumber(totalVolumeDelta, {
                      currency: true,
                      compact: true,
                    })}
                  </span>
                )}
              </div>
            </>
          ) : totalVolumeLoading ? (
            <RiLoader5Fill size="3em" className="loader" />
          ) : (
            <>
              <div className="total-stat-label">Total Volume</div>
              <div className="total-stat-value">
                {formatNumber(0, { currency: true })}
              </div>
            </>
          )}
        </div>
        <div className="chart-cell stats">
          {totalFees ? (
            <>
              <div className="total-stat-label">Total Fees</div>
              <div className="total-stat-value">
                {formatNumber(totalFees, { currency: true })}
                <span
                  className="total-stat-delta plus"
                  title="Change since previous day"
                >
                  +
                  {formatNumber(totalFeesDelta, {
                    currency: true,
                    compact: true,
                  })}
                </span>
              </div>
            </>
          ) : totalFeesLoading ? (
            <RiLoader5Fill size="3em" className="loader" />
          ) : (
            <>
              <div className="total-stat-label">Total Fees</div>
              <div className="total-stat-value">
                {formatNumber(0, { currency: true })}
              </div>
            </>
          )}
        </div>
        <div className="chart-cell stats">
          {totalAum ? (
            <>
              <div className="total-stat-label">QLP Pool</div>
              <div className="total-stat-value">
                {formatNumber(totalAum, { currency: true })}
                <span
                  className={cx(
                    "total-stat-delta",
                    totalAumDelta > 0 ? "plus" : "minus"
                  )}
                  title="Change since previous day"
                >
                  {totalAumDelta > 0 ? "+" : ""}
                  {formatNumber(totalAumDelta, {
                    currency: true,
                    compact: true,
                  })}
                </span>
              </div>
            </>
          ) : (
            <RiLoader5Fill size="3em" className="loader" />
          )}
        </div>
        <div className="chart-cell stats">
          {totalUsers ? (
            <>
              <div className="total-stat-label">Total Users</div>
              <div className="total-stat-value">
                {formatNumber(totalUsers)}
                <span
                  className="total-stat-delta plus"
                  title="Change since previous day"
                >
                  +{formatNumber(totalUsersDelta)}
                </span>
              </div>
            </>
          ) : totalUsersLoading ? (
            <RiLoader5Fill size="3em" className="loader" />
          ) : (
            <>
              <div className="total-stat-label">Total Users</div>
              <div className="total-stat-value">{formatNumber(0)}</div>
            </>
          )}
        </div>
        <div className="chart-cell stats">
          {openInterest ? (
            <>
              <div className="total-stat-label">Open Interest</div>
              <div className="total-stat-value">
                {formatNumber(openInterest, { currency: true })}
                <span
                  className={cx(
                    "total-stat-delta",
                    openInterestDelta > 0 ? "plus" : "minus"
                  )}
                  title="Change since previous day"
                >
                  {openInterestDelta > 0 ? "+" : ""}
                  {formatNumber(openInterestDelta, {
                    currency: true,
                    compact: true,
                  })}
                </span>
              </div>
            </>
          ) : totalTradersLoading ? (
            <RiLoader5Fill size="3em" className="loader" />
          ) : (
            <>
              <div className="total-stat-label">Open Interest</div>
              <div className="total-stat-value">
                {formatNumber(0, { currency: true })}
              </div>
            </>
          )}
        </div>
        <div className="chart-cell">
          <VolumeChart
            data={volumeData}
            loading={volumeLoading}
            chartHeight={CHART_HEIGHT}
            yaxisWidth={YAXIS_WIDTH}
            xaxisTickFormatter={tooltipLabelFormatter}
            yaxisTickFormatter={yaxisFormatter}
            tooltipLabelFormatter={tooltipLabelFormatter}
            tooltipFormatter={tooltipFormatter}
          />
        </div>
        <div className="chart-cell">
          <FeesChart
            data={feesData}
            loading={feesLoading}
            chartHeight={CHART_HEIGHT}
            yaxisWidth={YAXIS_WIDTH}
            xaxisTickFormatter={tooltipLabelFormatter}
            yaxisTickFormatter={yaxisFormatter}
            tooltipLabelFormatter={tooltipLabelFormatter}
            tooltipFormatter={tooltipFormatter}
          />
        </div>
        <div className="chart-cell">
          <ChartWrapper
            title="AUM & QLP Supply"
            loading={qlpLoading}
            data={qlpData}
            csvFields={[{ key: "aum" }, { key: "qlpSupply" }]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <LineChart data={qlpData} syncId="syncQlp">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                />
                <YAxis
                  dataKey="qlpSupply"
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                  domain={[0, 3000000]}
                />
                <Tooltip
                  formatter={tooltipFormatterNumber}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: "left" }}
                />
                <Legend />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  strokeWidth={2}
                  unit="$"
                  dot={false}
                  dataKey="aum"
                  stackId="a"
                  name="AUM"
                  stroke={COLORS[0]}
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  strokeWidth={2}
                  dot={false}
                  dataKey="qlpSupply"
                  stackId="a"
                  name="QLP Supply"
                  stroke={COLORS[1]}
                />
              </LineChart>
            </ResponsiveContainer>
          </ChartWrapper>
        </div>

        <div className="chart-cell">
          <PoolAmountChart 
            from={from}
            to={to}
            chainName={params.chainName}
            syncId="syncQlp"
          />
        </div>
        
        <div className="chart-cell">
          <ChartWrapper
            title="QLP Performance"
            loading={qlpLoading}
            data={qlpPerformanceData}
            csvFields={[
              { key: "syntheticPrice" },
              { key: "qlpPrice" },
              { key: "qlpPlusFees" },
              { key: "lpBtcPrice" },
              { key: "lpEthPrice" },
              { key: "performanceSyntheticCollectedFees" },
              { key: "indexBtcCount" },
              { key: "indexEthCount" },
              { key: "indexAvaxCount" },
              { key: "indexStableCount" },
              { key: "BTC_WEIGHT" },
              { key: "ETH_WEIGHT" },
              { key: "MATIC_WEIGHT" },
              { key: "STABLE_WEIGHT" },
            ]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <LineChart data={qlpPerformanceData} syncId="syncQlp">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                />
                <YAxis
                  dataKey="performanceSyntheticCollectedFees"
                  domain={[0, 180]}
                  unit="%"
                  tickFormatter={yaxisFormatterNumber}
                  width={YAXIS_WIDTH}
                />
                <Tooltip
                  formatter={tooltipFormatterNumber}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: "left" }}
                />
                <Legend />
                <Line
                  dot={false}
                  isAnimationActive={false}
                  type="monotone"
                  unit="%"
                  dataKey="performanceLpBtcCollectedFees"
                  name="% LP BTC-USDC (w/ fees)"
                  stroke={COLORS[2]}
                />
                <Line
                  dot={false}
                  isAnimationActive={false}
                  type="monotone"
                  unit="%"
                  dataKey="performanceLpEthCollectedFees"
                  name="% LP ETH-USDC (w/ fees)"
                  stroke={COLORS[4]}
                />
                <Line
                  dot={false}
                  isAnimationActive={false}
                  type="monotone"
                  unit="%"
                  dataKey="performanceSyntheticCollectedFees"
                  name="% Index (w/ fees)"
                  stroke={COLORS[0]}
                />
              </LineChart>
            </ResponsiveContainer>
            <div className="chart-description">
              <p>
                <span style={{ color: COLORS[0] }}>% of Index (with fees)</span>{" "}
                is QLP with fees / Index Price * 100. Index is a basket of 25%
                BTC, 25% ETH, 50% USDC rebalanced once&nbsp;a&nbsp;day
                <br />
                <span style={{ color: COLORS[4] }}>
                  % of LP ETH-USDC (with fees)
                </span>{" "}
                is QLP Price with fees / LP ETH-USDC * 100
                <br />
              </p>
            </div>
          </ChartWrapper>
        </div>

        <div className="chart-cell">
          <ChartWrapper
            title="QLP Price Comparison"
            loading={qlpLoading}
            data={qlpPerformanceData}
            csvFields={[
              { key: "syntheticPrice" },
              { key: "qlpPrice" },
              { key: "qlpPlusFees" },
              { key: "lpBtcPrice" },
              { key: "lpEthPrice" },
              { key: "performanceSyntheticCollectedFees" },
            ]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <LineChart data={qlpPerformanceData} syncId="syncQlp">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                />
                <YAxis
                  dataKey="performanceSyntheticCollectedFees"
                  domain={[0, 210]}
                  unit="%"
                  tickFormatter={yaxisFormatterNumber}
                  width={YAXIS_WIDTH}
                />
                <YAxis
                  dataKey="qlpPrice"
                  domain={[0.4, 1.7]}
                  orientation="right"
                  yAxisId="right"
                  tickFormatter={yaxisFormatterNumber}
                  width={YAXIS_WIDTH}
                />
                <Tooltip
                  formatter={tooltipFormatterNumber}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: "left" }}
                />
                <Legend />
                <Line
                  dot={false}
                  isAnimationActive={false}
                  type="monotone"
                  unit="%"
                  strokeWidth={2}
                  dataKey="performanceLpBtcCollectedFees"
                  name="% LP BTC-USDC (w/ fees)"
                  stroke={COLORS[2]}
                />
                <Line
                  dot={false}
                  isAnimationActive={false}
                  type="monotone"
                  unit="%"
                  strokeWidth={2}
                  dataKey="performanceLpEthCollectedFees"
                  name="% LP ETH-USDC (w/ fees)"
                  stroke={COLORS[4]}
                />
                <Line
                  dot={false}
                  isAnimationActive={false}
                  type="monotone"
                  unit="%"
                  strokeWidth={2}
                  dataKey="performanceSyntheticCollectedFees"
                  name="% Index (w/ fees)"
                  stroke={COLORS[0]}
                />

                <Line
                  isAnimationActive={false}
                  type="monotone"
                  unit="$"
                  strokeWidth={1}
                  yAxisId="right"
                  dot={false}
                  dataKey="syntheticPrice"
                  name="Index Price"
                  stroke={COLORS[2]}
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  unit="$"
                  strokeWidth={1}
                  yAxisId="right"
                  dot={false}
                  dataKey="qlpPrice"
                  name="QLP Price"
                  stroke={COLORS[1]}
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  unit="$"
                  strokeWidth={1}
                  yAxisId="right"
                  dot={false}
                  dataKey="qlpPlusFees"
                  name="QLP w/ fees"
                  stroke={COLORS[3]}
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  unit="$"
                  strokeWidth={1}
                  yAxisId="right"
                  dot={false}
                  dataKey="lpBtcPrice"
                  name="LP BTC-USDC"
                  stroke={COLORS[2]}
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  unit="$"
                  strokeWidth={1}
                  yAxisId="right"
                  dot={false}
                  dataKey="lpEthPrice"
                  name="LP ETH-USDC"
                  stroke={COLORS[4]}
                />
              </LineChart>
            </ResponsiveContainer>
            <div className="chart-description">
              <p>
                <span style={{ color: COLORS[3] }}>QLP with fees</span> is
                based on QLP share of fees received
                <br />
                <span style={{ color: COLORS[0] }}>
                  % of Index (with fees)
                </span>{" "}
                is QLP with fees / Index Price * 100
                <br />
                <span style={{ color: COLORS[4] }}>
                  % of LP ETH-USDC (with fees)
                </span>{" "}
                is QLP Price with fees / LP ETH-USDC * 100
                <br />
                <span style={{ color: COLORS[2] }}>Index Price</span> is 25%
                BTC, 25% ETH, 50% USDC
              </p>
            </div>
          </ChartWrapper>
        </div>
        {isExperiment && (
          <div className="chart-cell experiment">
            <ChartWrapper title="Performance vs. Index" loading={qlpLoading}>
              <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                <LineChart data={qlpPerformanceData} syncId="syncQlp">
                  <CartesianGrid strokeDasharray="10 10" />
                  <XAxis
                    dataKey="timestamp"
                    tickFormatter={tooltipLabelFormatter}
                    minTickGap={30}
                  />
                  <YAxis
                    dataKey="performanceSyntheticCollectedFees"
                    domain={[80, 120]}
                    unit="%"
                    tickFormatter={yaxisFormatterNumber}
                    width={YAXIS_WIDTH}
                  />
                  <Tooltip
                    formatter={tooltipFormatterNumber}
                    labelFormatter={tooltipLabelFormatter}
                    contentStyle={{ textAlign: "left" }}
                  />
                  <Legend />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceSyntheticCollectedFees"
                    name="Collected Fees"
                    stroke={COLORS[0]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceSyntheticDistributedUsd"
                    name="Distributed Usd"
                    stroke={COLORS[1]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceSyntheticDistributedEth"
                    name="Distributed Eth"
                    stroke={COLORS[2]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceSynthetic"
                    name="No Fees"
                    stroke={COLORS[3]}
                  />
                </LineChart>
              </ResponsiveContainer>
            </ChartWrapper>
          </div>
        )}
        {isExperiment && (
          <div className="chart-cell experiment">
            <ChartWrapper title="Performance vs. ETH LP" loading={qlpLoading}>
              <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                <LineChart data={qlpPerformanceData} syncId="syncQlp">
                  <CartesianGrid strokeDasharray="10 10" />
                  <XAxis
                    dataKey="timestamp"
                    tickFormatter={tooltipLabelFormatter}
                    minTickGap={30}
                  />
                  <YAxis
                    dataKey="performanceLpEthCollectedFees"
                    domain={[80, 120]}
                    unit="%"
                    tickFormatter={yaxisFormatterNumber}
                    width={YAXIS_WIDTH}
                  />
                  <Tooltip
                    formatter={tooltipFormatterNumber}
                    labelFormatter={tooltipLabelFormatter}
                    contentStyle={{ textAlign: "left" }}
                  />
                  <Legend />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceLpEthCollectedFees"
                    name="Collected Fees"
                    stroke={COLORS[0]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceLpEthDistributedUsd"
                    name="Distributed Usd"
                    stroke={COLORS[1]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceLpEthDistributedEth"
                    name="Distributed Eth"
                    stroke={COLORS[2]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceLpEth"
                    name="No Fees"
                    stroke={COLORS[3]}
                  />
                </LineChart>
              </ResponsiveContainer>
            </ChartWrapper>
          </div>
        )}
        <div className="chart-cell">
          <ChartWrapper
            title="Traders Net PnL"
            loading={tradersLoading}
            data={tradersData?.data}
            csvFields={[
              { key: "pnl", name: "Net PnL" },
              { key: "pnlCumulative", name: "Cumulative PnL" },
            ]}
          >
            <ResponsiveContainer
              width="100%"
              syncId="tradersId"
              height={CHART_HEIGHT}
            >
              <ComposedChart data={tradersData?.data}>
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                />
                <YAxis
                  domain={[
                    -tradersData?.stats.maxAbsOfPnlAndCumulativePnl * 1.05,
                    tradersData?.stats.maxAbsOfPnlAndCumulativePnl * 1.05,
                  ]}
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                />
                <Tooltip
                  formatter={tooltipFormatter}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: "left" }}
                />
                <Legend />
                <Bar
                  type="monotone"
                  fill={mode == "dark" ? "#FFFFFF" : "#000000"}
                  dot={false}
                  dataKey="pnl"
                  name="Net PnL"
                >
                  {(tradersData?.data || []).map((item, i) => {
                    return (
                      <Cell
                        key={`cell-${i}`}
                        fill={item.pnl > 0 ? "#22c761" : "#f93333"}
                      />
                    );
                  })}
                </Bar>
                <Line
                  type="monotone"
                  strokeWidth={2}
                  stroke={COLORS[4]}
                  dataKey="pnlCumulative"
                  name="Cumulative PnL"
                />
              </ComposedChart>
            </ResponsiveContainer>
            <div className="chart-description">
              <p>Considers settled (closed) positions</p>
              <p>Fees are not factored into PnL</p>
            </div>
          </ChartWrapper>
        </div>
        <div className="chart-cell">
          <ChartWrapper
            title="Traders Profit vs. Loss"
            loading={tradersLoading}
            data={tradersData?.data}
            csvFields={[
              { key: "profit" },
              { key: "loss" },
              { key: "profitCumulative" },
              { key: "lossCumulative" },
            ]}
          >
            <ResponsiveContainer
              width="100%"
              syncId="tradersId"
              height={CHART_HEIGHT}
            >
              <ComposedChart data={tradersData?.data} barGap={0}>
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                />
                <YAxis
                  domain={[
                    -tradersData?.stats.maxProfitLoss * 1.05,
                    tradersData?.stats.maxProfitLoss * 1.05,
                  ]}
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                />
                <YAxis
                  domain={[
                    -tradersData?.stats.maxCumulativeProfitLoss * 1.1,
                    tradersData?.stats.maxCumulativeProfitLoss * 1.1,
                  ]}
                  orientation="right"
                  yAxisId="right"
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                />
                <Tooltip
                  formatter={tooltipFormatter}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: "left" }}
                />
                <Legend />
                <Area
                  yAxisId="right"
                  type="monotone"
                  stroke={0}
                  fill="#22c761"
                  fillOpacity="0.4"
                  dataKey="profitCumulative"
                  name="Cumulative Profit"
                />
                <Area
                  yAxisId="right"
                  type="monotone"
                  stroke={0}
                  fill="#f93333"
                  fillOpacity="0.4"
                  dataKey="lossCumulative"
                  name="Cumulative Loss"
                />
                <Bar
                  type="monotone"
                  fill="#22c761"
                  dot={false}
                  dataKey="profit"
                  name="Profit"
                />
                <Bar
                  type="monotone"
                  fill="#f93333"
                  dot={false}
                  dataKey="loss"
                  name="Loss"
                />
              </ComposedChart>
            </ResponsiveContainer>
            <div className="chart-description">
              <p>Considers settled (closed) positions</p>
              <p>Fees are not factored into PnL</p>
            </div>
          </ChartWrapper>
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={fundingRateLoading}
            title="Borrowing Rate Annualized"
            data={fundingRateData}
            yaxisDataKey="ETH"
            yaxisTickFormatter={yaxisFormatterPercent}
            tooltipFormatter={tooltipFormatterPercent}
            items={[
              { key: "WETH", color: "#6185F5" },
              { key: "BTC", color: "#F7931A" },
              { key: "MATIC", color: "#7C43DA" },
              { key: "USDC", color: "#2775CA" },
              { key: "USDT", color: "#67B18A" },
              { key: "DAI", color: "#FAC044" },
            ]}
            type="Line"
            yaxisDomain={[0, 90 /* ~87% is a maximum yearly borrow rate */]}
            isCoinChart={true}
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={tradersLoading}
            title="Open Interest"
            data={tradersData?.data.map((item) => ({
              all: item.openInterest,
              ...item,
            }))}
            yaxisDataKey="openInterest"
            items={[
              { key: "shortOpenInterest", name: "Short", color: "#f93333" },
              { key: "longOpenInterest", name: "Long", color: "#22c761" },
            ]}
            type="Bar"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncQlp"
            loading={aumPerformanceLoading}
            title="AUM Performance Annualized"
            data={aumPerformanceData}
            yaxisDataKey="apr"
            yaxisTickFormatter={yaxisFormatterPercent}
            tooltipFormatter={tooltipFormatterPercent}
            items={[{ key: "apr", name: "APR", color: COLORS[0] }]}
            description="Formula = Daily Fees / QLP Pool * 365 days * 100"
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncQlp"
            loading={aumPerformanceLoading}
            title="AUM Daily Usage"
            data={aumPerformanceData}
            yaxisDataKey="usage"
            yaxisTickFormatter={yaxisFormatterPercent}
            tooltipFormatter={tooltipFormatterPercent}
            items={[{ key: "usage", name: "Daily Usage", color: COLORS[4] }]}
            description="Formula = Daily Volume / QLP Pool * 100"
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncQlp"
            loading={usersLoading}
            title="Unique Users"
            data={usersData}
            yaxisDataKey="uniqueSum"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={tooltipFormatterNumber}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: "uniqueSwapCount", name: "Swaps" },
              { key: "uniqueMarginCount", name: "Margin trading" },
              { key: "uniqueMintBurnCount", name: "Mint & Burn QLP" },
            ]}
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncQlp"
            loading={usersLoading}
            title="New Users"
            data={usersData?.map((item) => ({ ...item, all: item.newCount }))}
            yaxisDataKey="newCount"
            rightYaxisDataKey="uniqueCountCumulative"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={tooltipFormatterNumber}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: "newSwapCount", name: "Swap" },
              { key: "newMarginCount", name: "Margin trading" },
              { key: "newMintBurnCount", name: "Mint & Burn" },
              {
                key: "uniqueCountCumulative",
                name: "Cumulative",
                type: "Line",
                yAxisId: "right",
                strokeWidth: 2,
                color: COLORS[4],
              },
            ]}
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncQlp"
            loading={usersLoading}
            title="New vs. Existing Users"
            data={usersData?.map((item) => ({
              ...item,
              all: item.uniqueCount,
            }))}
            yaxisDataKey="all"
            rightYaxisDataKey="oldPercent"
            rightYaxisDomain={[0,100]}
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={tooltipFormatterNumber}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: "newCount", name: "New" },
              { key: "oldCount", name: "Existing" },
              {
                key: "oldPercent",
                name: "Existing %",
                yAxisId: "right",
                type: "Line",
                strokeWidth: 2,
                color: COLORS[4],
                unit: "%",
              },
            ]}
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncQlp"
            loading={usersLoading}
            title="User Actions"
            data={(usersData || []).map((item) => ({
              ...item,
              all: item.actionCount,
            }))}
            yaxisDataKey="actionCount"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={tooltipFormatterNumber}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: "actionSwapCount", name: "Swaps" },
              { key: "actionMarginCount", name: "Margin trading" },
              { key: "actionMintBurnCount", name: "Mint & Burn QLP" },
            ]}
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={swapSourcesLoading}
            title="Swap Sources"
            data={swapSources}
            items={swapSourcesKeys.map((key) => ({ key }))}
          />
        </div>
      </div>
    </div>
  );
}

export default Polygon;
