import { useMemo, useState, useEffect } from "react";
import { ApolloClient, InMemoryCache, gql, HttpLink } from "@apollo/client";
import { chain, sumBy, sortBy, maxBy, minBy } from "lodash";
import fetch from "cross-fetch";
import * as ethers from "ethers";

import { getAddress, POLYGON_ZKEVM } from "./addresses";

const { JsonRpcProvider } = ethers.providers;

import RewardReader from "../abis/RewardReader.json";
import QlpManager from "../abis/QlpManager.json";
import Token from "../abis/v1/Token.json";
import { fillPeriodsWithDefaults } from "./helpers";

const subgraphUrl = process.env.RAZZLE_SUBGRAPH_URL;
const referralSubgraphUrl = process.env.RAZZLE_REFERRAL_SUBGRAPH_URL;

const providers = {
  polygon_zkevm: new JsonRpcProvider("https://zkevm-rpc.com"),
};

function getProvider(chainName) {
  if (!(chainName in providers)) {
    throw new Error(`Unknown chain ${chainName}`);
  }
  return providers["polygon_zkevm"];
}

function getChainId(chainName) {
  const chainId = {
    polygon_zkevm: POLYGON_ZKEVM,
  }[chainName];
  if (!chainId) {
    throw new Error(`Unknown chain ${chainName}`);
  }
  return chainId;
}

const DEFAULT_GROUP_PERIOD = 86400;
export const NOW_TS = parseInt(Date.now() / 1000);
export const FROM_DATE_TS = NOW_TS - NOW_TS % 86400 - DEFAULT_GROUP_PERIOD * 30; // 15 day before
export const FIRST_DATE_TS = parseInt(+new Date("2023-05-01") / 1000);

function fillNa(arr, keys) {
  const prevValues = {};
  if (!keys && arr.length > 0) {
    keys = Object.keys(arr[0]);
    delete keys.timestamp;
    delete keys.id;
  }
  for (const el of arr) {
    for (const key of keys) {
      if (!el[key]) {
        if (prevValues[key]) {
          el[key] = prevValues[key];
        }
      } else {
        prevValues[key] = el[key];
      }
    }
  }
  return arr;
}


export async function queryEarnData(chainName, account) {
  const provider = getProvider(chainName);
  const chainId = getChainId(chainName);
  const rewardReader = new ethers.Contract(getAddress(chainId, "RewardReader"), RewardReader.abi, provider);
  const qlpContract = new ethers.Contract(getAddress(chainId, "QLP"), Token.abi, provider);
  const qlpManager = new ethers.Contract(getAddress(chainId, "QlpManager"), QlpManager.abi, provider);

  let depositTokens;
  let rewardTrackersForDepositBalances;
  let rewardTrackersForStakingInfo;

  if (chainId === POLYGON_ZKEVM) {
    depositTokens = [
      getAddress(POLYGON_ZKEVM, "QLP"),
    ];
    rewardTrackersForDepositBalances = [
      getAddress(POLYGON_ZKEVM, "FEE_QLP_TRACKER"),
    ];
    rewardTrackersForStakingInfo = [
      getAddress(POLYGON_ZKEVM, "FEE_QLP_TRACKER"),
    ];
  }

  const [balances, stakingInfo, qlpTotalSupply, qlpAum] = await Promise.all([
    rewardReader.getDepositBalances(account, depositTokens, rewardTrackersForDepositBalances),
    rewardReader.getStakingInfo(account, rewardTrackersForStakingInfo).then((info) => {
      return rewardTrackersForStakingInfo.map((_, i) => {
        return info.slice(i * 5, (i + 1) * 5);
      });
    }),
    qlpContract.totalSupply(),
    qlpManager.getAumInUsdq(true)
  ]);

  const qlpPrice = qlpAum / 1e18 / (qlpTotalSupply / 1e18);
  const now = new Date();

  return {
    QLP: {
      stakedQLP: balances[5] / 1e18,
      pendingETH: stakingInfo[4][0] / 1e18,
      qlpPrice,
    },
    timestamp: parseInt(now / 1000),
    datetime: now.toISOString(),
  };
}

export const tokenDecimals = {
  "0xa2036f0538221a77a3937f1379699f44945018d0": 18, // MATIC
  "0x4f9a0e7fd2bf6067db6994cf12e4495df938e6e9": 18, // WETH
  "0xea034fb02eb1808c2cc3adbc15f447b93cbe08e1": 8, // BTC
  "0xa8ce8aee21bc2a48a5ef670afcc9274c7bbbc035": 6, // USDC
  "0x1e4a5963abfd975d8c9021ce480b42188849d41d": 6, // USDT
  "0xc5015b9d9161dca7e18e32f6f25c4ad850731fd4": 18, // DAI
};

export const tokenSymbols = {
  // Polygon zkEVM
  "0xa2036f0538221a77a3937f1379699f44945018d0": "MATIC",
  "0x4f9a0e7fd2bf6067db6994cf12e4495df938e6e9": "WETH",
  "0xea034fb02eb1808c2cc3adbc15f447b93cbe08e1": "BTC",
  "0xa8ce8aee21bc2a48a5ef670afcc9274c7bbbc035": "USDC",
  "0x1e4a5963abfd975d8c9021ce480b42188849d41d": "USDT",
  "0xc5015b9d9161dca7e18e32f6f25c4ad850731fd4": "DAI",
};

const knownBotSwapSources = [];
const knownSwapSources = {
  polygon_zkevm: {
    [getAddress(POLYGON_ZKEVM, "Router")]: "QPERP",
    [getAddress(POLYGON_ZKEVM, "OrderBook")]: "QPERP",
    [getAddress(POLYGON_ZKEVM, "PositionManager")]: "QPERP",
    [getAddress(POLYGON_ZKEVM, "FastPriceFeed")]: "QPERP",
    [getAddress(POLYGON_ZKEVM, "PositionRouter")]: "QPERP",
    [getAddress(POLYGON_ZKEVM, "RewardRouter")]: "QPERP",
  },
};

const defaultFetcher = (url) => fetch(url).then((res) => res.json());
export function useRequest(url, defaultValue, fetcher = defaultFetcher) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState(defaultValue);

  useEffect(async () => {
    try {
      setLoading(true);
      const data = await fetcher(url);
      setData(data);
    } catch (ex) {
      console.error(ex);
      setError(ex);
    }
    setLoading(false);
  }, [url]);

  return [data, loading, error];
}

export function useCoingeckoPrices(symbol, { from = FIRST_DATE_TS } = {}) {
  // token ids https://api.coingecko.com/api/v3/coins
  const _symbol = {
    BTC: "bitcoin",
    ETH: "ethereum",
    MATIC: "matic-network",
    WBTC: "wrapped-bitcoin",
    USDC: "usd-coin",
    USDT: "tether",
    DAI: "dai",
  }[symbol];

  const now = Date.now() / 1000;
  const days = Math.ceil(now / 86400) - Math.ceil(from / 86400) - 1;

  const url = `https://api.coingecko.com/api/v3/coins/${_symbol}/market_chart?vs_currency=usd&days=${days}&interval=daily`;

  const [res, loading, error] = useRequest(url);

  const data = useMemo(() => {
    if (!res || res.length === 0) {
      return null;
    }

    const ret = res.prices.map((item) => {
      // -1 is for shifting to previous day
      // because CG uses first price of the day, but for QLP we store last price of the day
      const timestamp = item[0] - 1;
      const groupTs = parseInt(timestamp / 1000 / 86400) * 86400;
      return {
        timestamp: groupTs,
        value: item[1],
      };
    });
    return ret;
  }, [res]);

  return [data, loading, error];
}

function getImpermanentLoss(change) {
  return (2 * Math.sqrt(change)) / (1 + change) - 1;
}

export function useGraph(querySource, { subgraph = null, subgraphUrl = null, chainName = "polygon_zkevm" } = {}) {
  const query = gql(querySource);
  const client = new ApolloClient({
    link: new HttpLink({ uri: subgraphUrl, fetch }),
    cache: new InMemoryCache(),
  });
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
  }, [querySource, setLoading]);

  useEffect(() => {
    client
      .query({ query })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((ex) => {
        console.warn("Subgraph request failed error: %s subgraphUrl: %s", ex.message, subgraphUrl);
        setError(ex);
        setLoading(false);
      });
  }, [querySource, setData, setError, setLoading]);

  return [data, loading, error];
}

export function useLastBlock(chainName = "polygon_zkevm") {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    providers[chainName]
      .getBlock()
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  return [data, loading, error];
}

export function useLastSubgraphBlock(chainName = "polygon_zkevm") {
  const [data, loading, error] = useGraph(
    `{
    _meta {
      block {
        number
      }
    } 
  }`,
    {
      chainName,
      subgraphUrl,
    }
  );
  const [block, setBlock] = useState(null);

  useEffect(() => {
    if (!data) {
      return;
    }

    providers[chainName].getBlock(data._meta.block.number).then((block) => {
      setBlock(block);
    });
  }, [data, setBlock]);

  return [block, loading, error];
}

export function useTradersData({ from = FROM_DATE_TS, to = NOW_TS, chainName = "polygon_zkevm" } = {}) {
  const [closedPositionsData, loading, error] = useGraph(
    `{
    tradingStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
    ) {
      timestamp
      profit
      loss
      profitCumulative
      lossCumulative
      longOpenInterest
      shortOpenInterest
    }
  }`,
    { chainName, subgraphUrl }
  );
  const [feesData] = useFeesData({ from, to, chainName });
  const marginFeesByTs = useMemo(() => {
    if (!feesData || !closedPositionsData || (closedPositionsData && !closedPositionsData.tradingStats.length)) {
      return {};
    }

    let feesCumulative = 0;
    return feesData.reduce((memo, { timestamp, margin: fees }) => {
      feesCumulative += fees;
      memo[timestamp] = {
        fees,
        feesCumulative,
      };
      return memo;
    }, {});
  }, [feesData]);

  let ret = null;
  const data =
    closedPositionsData && closedPositionsData.tradingStats.length > 0
      ? sortBy(closedPositionsData.tradingStats, (i) => i.timestamp).map((dataItem) => {
          const longOpenInterest = dataItem.longOpenInterest / 1e30;
          const shortOpenInterest = dataItem.shortOpenInterest / 1e30;
          const openInterest = longOpenInterest + shortOpenInterest;

          const fees = marginFeesByTs[dataItem.timestamp]?.fees || 0;
          const feesCumulative = marginFeesByTs[dataItem.timestamp]?.feesCumulative || 0;

          const profit = dataItem.profit / 1e30;
          const loss = dataItem.loss / 1e30;
          const profitCumulative = dataItem.profitCumulative / 1e30;
          const lossCumulative = dataItem.lossCumulative / 1e30;
          const pnlCumulative = profitCumulative - lossCumulative;
          const pnl = profit - loss;
          return {
            longOpenInterest,
            shortOpenInterest,
            openInterest,
            profit,
            loss: -loss,
            profitCumulative,
            lossCumulative: -lossCumulative,
            pnl,
            pnlCumulative,
            timestamp: dataItem.timestamp,
          };
        })
      : null;

  if (data) {
    const maxProfit = maxBy(data, (item) => item.profit).profit;
    const maxLoss = minBy(data, (item) => item.loss).loss;
    const maxProfitLoss = Math.max(maxProfit, -maxLoss);

    const maxPnl = maxBy(data, (item) => item.pnl).pnl;
    const minPnl = minBy(data, (item) => item.pnl).pnl;
    const maxCumulativePnl = maxBy(data, (item) => item.pnlCumulative).pnlCumulative;
    const minCumulativePnl = minBy(data, (item) => item.pnlCumulative).pnlCumulative;

    const profitCumulative = data[data.length - 1].profitCumulative;
    const lossCumulative = data[data.length - 1].lossCumulative;
    const stats = {
      maxProfit,
      maxLoss,
      maxProfitLoss,
      profitCumulative,
      lossCumulative,
      maxCumulativeProfitLoss: Math.max(profitCumulative, -lossCumulative),

      maxAbsOfPnlAndCumulativePnl: Math.max(
        Math.abs(maxPnl),
        Math.abs(maxCumulativePnl),
        Math.abs(minPnl),
        Math.abs(minCumulativePnl)
      ),
    };

    ret = {
      data,
      stats,
    };
  }

  return [ret, loading];
}

function getSwapSourcesFragment(skip = 0, from, to) {
  return `
    dailyVolumeBySources(
      first: 1000
      skip: ${skip}
      orderBy: timestamp
      orderDirection: desc
      where: { timestamp_gte: ${from}, timestamp_lte: ${to} }
    ) {
      timestamp
      source
      swap
    }
  `;
}
export function useSwapSources({ from = FROM_DATE_TS, to = NOW_TS, chainName = "polygon_zkevm" } = {}) {
  const query = `{
    a: ${getSwapSourcesFragment(0, from, to)}
    b: ${getSwapSourcesFragment(1000, from, to)}
    c: ${getSwapSourcesFragment(2000, from, to)}
    d: ${getSwapSourcesFragment(3000, from, to)}
    e: ${getSwapSourcesFragment(4000, from, to)}
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName, subgraphUrl });
  // const [graphData, loading, error] = useRequest('/api/graphql/core?'+new URLSearchParams({query: query}).toString())

  let total = 0;
  let data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    const { a, b, c, d, e } = graphData;
    const all = [...a, ...b, ...c, ...d, ...e];

    const totalVolumeBySource = a.reduce((acc, item) => {
      const source = knownSwapSources[chainName][item.source] || item.source;
      if (!acc[source]) {
        acc[source] = 0;
      }
      acc[source] += item.swap / 1e30;
      return acc;
    }, {});
    const topVolumeSources = new Set(
      Object.entries(totalVolumeBySource)
        .sort((a, b) => b[1] - a[1])
        .map((item) => item[0])
        .slice(0, 8)
    );
    let ret = chain(all)
      .groupBy((item) => parseInt(item.timestamp / 86400) * 86400)
      .map((values, timestamp) => {
        let all = 0;
        const retItem = {
          timestamp: Number(timestamp),
          ...values.reduce((memo, item) => {
            let source = knownSwapSources[chainName][item.source] || item.source;
            if (knownBotSwapSources.find(s=>s === source)) {
              source = "Other";
            }
            if (!topVolumeSources.has(source) && knownSwapSources[chainName][item.source] === undefined) {
              source = "Other";
            }
            if (item.swap != 0) {
              const volume = item.swap / 1e30;
              memo[source] = memo[source] || 0;

              memo[source] += volume;
              all += volume;
            }
            return memo;
          }, {}),
        };

        retItem.all = all;

        return retItem;
      })
      .sortBy((item) => item.timestamp)
      .value();

    return ret;
  }, [graphData]);

  return [data, loading, error];
}

function getServerHostname(chainName) {
  return process.env.RAZZLE_QPERP_API_URL;
}

export function useTotalVolumeFromServer() {
  const [data, loading] = useRequest(getServerHostname() + "/total_volume");

  return useMemo(() => {
    if (!data) {
      return [data, loading];
    }

    const total = data.reduce((memo, item) => {
      return memo + parseInt(item.data.volume) / 1e30;
    }, 0);
    return [total, loading];
  }, [data, loading]);
}

export async function getStatsFromSubgraph(graphClient, chainName = "polygon_zkevm") {
  const queryString = `{
    totalVolumes: volumeStats(where: {period: "total"}) {
      swap
      mint
      burn
      margin
      liquidation
    }
    deltaVolumes: volumeStats(
      first:1
      orderBy: timestamp
      orderDirection: desc
      where: {period: "daily"}
    ) {
      swap
      mint
      burn
      margin
      liquidation
    }
  	totalFees: feeStats(where: {period: "total"}) {
      swap
      mint
      burn
      margin
      liquidation
		}
    deltaFees: feeStats(
      first:1
      orderBy: timestamp
      orderDirection: desc
      where: {period: "daily"}
    ) {
      swap
      mint
      burn
      margin
      liquidation
    }
  }`;

  const query = gql(queryString);
  const { data } = await graphClient.query({ query });
  const statsProps = ["totalVolumes", "deltaVolumes", "totalFees", "deltaFees"];
  const methodProps = ["swap", "mint", "burn", "margin", "liquidation"];
  const result = {};
  console.log(data);
  statsProps.forEach((statsProp) => {
    result[statsProp] = {};
    let total = 0;
    methodProps.forEach((methodProp) => {
      const statValue = parseInt(data[statsProp][0][methodProp]) / 1e30;
      console.log(statValue);
      result[statsProp][methodProp] = statValue;
      total += statValue;
    });
    result[statsProp].total = total;
  });
  console.log(result);
  return result;
}

export function useVolumeDataFromServer({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "polygon_zkevm" } = {}) {
  const PROPS = "margin liquidation swap mint burn".split(" ");
  const [data, loading] = useRequest(`${getServerHostname(chainName)}/daily_volume`, null, async (url) => {
    let after;
    const ret = [];
    while (true) {
      const res = await (await fetch(url + (after ? `?after=${after}` : ""))).json();
      if (res.length === 0) return ret;
      for (const item of res) {
        if (item.data.timestamp < from) {
          return ret;
        }
        ret.push(item);
      }
      after = res[res.length - 1].id;
    }
  });

  const ret = useMemo(() => {
    if (!data) {
      return null;
    }

    const tmp = data.reduce((memo, item) => {
      const timestamp = item.data.timestamp;
      if (timestamp < from || timestamp > to) {
        return memo;
      }

      let type;
      if (item.data.action === "Swap") {
        type = "swap";
      } else if (item.data.action === "SellUSDQ") {
        type = "burn";
      } else if (item.data.action === "BuyUSDQ") {
        type = "mint";
      } else if (item.data.action.includes("LiquidatePosition")) {
        type = "liquidation";
      } else {
        type = "margin";
      }
      const volume = Number(item.data.volume) / 1e30;
      memo[timestamp] = memo[timestamp] || {};
      memo[timestamp][type] = memo[timestamp][type] || 0;
      memo[timestamp][type] += volume;
      return memo;
    }, {});

    let cumulative = 0;
    const cumulativeByTs = {};
    return Object.keys(tmp)
      .sort()
      .map((timestamp) => {
        const item = tmp[timestamp];
        let all = 0;

        let movingAverageAll;
        const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD;
        if (movingAverageTs in cumulativeByTs) {
          movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS;
        }

        PROPS.forEach((prop) => {
          if (item[prop]) all += item[prop];
        });
        cumulative += all;
        cumulativeByTs[timestamp] = cumulative;
        return {
          timestamp,
          all,
          cumulative,
          movingAverageAll,
          ...item,
        };
      });
  }, [data, from, to]);

  return [ret, loading];
}

export function useUsersData({ from = FROM_DATE_TS, to = NOW_TS, chainName = "polygon_zkevm" } = {}) {
  const query = `{
    userStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
    ) {
      uniqueCount
      uniqueSwapCount
      uniqueMarginCount
      uniqueMintBurnCount
      uniqueCountCumulative
      uniqueSwapCountCumulative
      uniqueMarginCountCumulative
      uniqueMintBurnCountCumulative
      actionCount
      actionSwapCount
      actionMarginCount
      actionMintBurnCount
      timestamp
    }
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName, subgraphUrl });

  const prevUniqueCountCumulative = {};
  const data = graphData
    ? sortBy(graphData.userStats, "timestamp").map((item) => {
        const newCountData = ["", "Swap", "Margin", "MintBurn"].reduce((memo, type) => {
          memo[`new${type}Count`] = prevUniqueCountCumulative[type]
            ? item[`unique${type}CountCumulative`] - prevUniqueCountCumulative[type]
            : item[`unique${type}Count`];
          prevUniqueCountCumulative[type] = item[`unique${type}CountCumulative`];
          return memo;
        }, {});
        const oldCount = item.uniqueCount - newCountData.newCount;
        const oldPercent = ((oldCount / item.uniqueCount) * 100).toFixed(1);
        return {
          all: item.uniqueCount,
          uniqueSum: item.uniqueSwapCount + item.uniqueMarginCount + item.uniqueMintBurnCount,
          oldCount,
          oldPercent,
          ...newCountData,
          ...item,
        };
      })
    : null;

  return [data, loading, error];
}

export function useFundingRateData({ from = FROM_DATE_TS, to = NOW_TS, chainName = "polygon_zkevm" } = {}) {
  const query = `{
    fundingRates(
      first: 1000,
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: "daily", id_gte: ${from}, id_lte: ${to} }
    ) {
      id,
      token,
      timestamp,
      startFundingRate,
      startTimestamp,
      endFundingRate,
      endTimestamp
    }
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName, subgraphUrl });

  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    const groups = graphData.fundingRates.reduce((memo, item) => {
      const symbol = tokenSymbols[item.token];
      memo[item.timestamp] = memo[item.timestamp] || {
        timestamp: item.timestamp,
      };
      const group = memo[item.timestamp];
      const timeDelta = parseInt((item.endTimestamp - item.startTimestamp) / 3600) * 3600;

      let fundingRate = 0;
      if (item.endFundingRate && item.startFundingRate) {
        const fundingDelta = item.endFundingRate - item.startFundingRate;
        const divisor = timeDelta / 86400;
        fundingRate = (fundingDelta / divisor / 10000) * 365;
      }
      group[symbol] = fundingRate;
      return memo;
    }, {});

    return fillNa(sortBy(Object.values(groups), "timestamp"), [
      "MATIC",
      "ETH",
      "USDC",
      "USDT",
      "BTC",
      "LINK",
      "DAI",
    ]);
  }, [graphData]);

  return [data, loading, error];
}

const MOVING_AVERAGE_DAYS = 7;
const MOVING_AVERAGE_PERIOD = 86400 * MOVING_AVERAGE_DAYS;

export function useVolumeData({ from = FROM_DATE_TS, to = NOW_TS, chainName = "polygon_zkevm" } = {}) {
  const PROPS = "margin liquidation swap mint burn".split(" ");
  const timestampProp = "timestamp";
  const query = `{
    volumeStats(
      first: 1000,
      orderBy: timestamp,
      orderDirection: desc
      where: { period: daily, timestamp_gte: ${from}, timestamp_lte: ${to} }
    ) {
      timestamp
      ${PROPS.join("\n")}
    }
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName, subgraphUrl });

  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    let ret = sortBy(graphData.volumeStats, timestampProp).map((item) => {
      const ret = { timestamp: item[timestampProp] };
      let all = 0;
      PROPS.forEach((prop) => {
        ret[prop] = item[prop] / 1e30;
        all += ret[prop];
      });
      ret.all = all;
      return ret;
    });

    let cumulative = 0;
    const cumulativeByTs = {};
    return ret.map((item) => {
      cumulative += item.all;

      let movingAverageAll;
      const movingAverageTs = item.timestamp - MOVING_AVERAGE_PERIOD;
      if (movingAverageTs in cumulativeByTs) {
        movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS;
      }

      return {
        movingAverageAll,
        cumulative,
        ...item,
      };
    });
  }, [graphData]);

  let total;
  let ret;
  if (data && data.length) {
    total = data[data.length - 1].cumulative;
    ret = fillPeriodsWithDefaults(data, 86400, from, to, "timestamp", { margin: 0, liquidation: 0, swap: 0, mint: 0, burn: 0 })
  }
  return [ret, total, loading, error];
}


export function useFeesData({ from = FROM_DATE_TS, to = NOW_TS, chainName = "polygon_zkevm" } = {}) {
  const PROPS = "margin liquidation swap mint burn".split(" ");
  const feesQuery = `{
    feeStats(
      first: 1000
      orderBy: id
      orderDirection: desc
      where: { period: daily, timestamp_gte: ${from}, timestamp_lte: ${to} }
    ) {
      id
      margin
      marginAndLiquidation
      swap
      mint
      burn
      timestamp
    }
  }`;

  let [feesData, loading, error] = useGraph(feesQuery, { chainName, subgraphUrl });

  const feesChartData = useMemo(() => {
    if (!feesData || (feesData && feesData.feeStats.length === 0)) {
      return null;
    }

    let chartData = sortBy(feesData.feeStats, "id").map((item) => {
      const ret = { timestamp: item.timestamp || item.id };

      PROPS.forEach((prop) => {
        if (item[prop]) {
          ret[prop] = item[prop] / 1e30;
        }
      });

      ret.liquidation = item.marginAndLiquidation / 1e30 - item.margin / 1e30;
      ret.all = PROPS.reduce((memo, prop) => memo + ret[prop], 0);
      return ret;
    });

    let cumulative = 0;
    const cumulativeByTs = {};
    return chain(chartData)
      .groupBy((item) => item.timestamp)
      .map((values, timestamp) => {
        const all = sumBy(values, "all");
        cumulative += all;

        let movingAverageAll;
        const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD;
        if (movingAverageTs in cumulativeByTs) {
          movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS;
        }

        const ret = {
          timestamp: Number(timestamp),
          all,
          cumulative,
          movingAverageAll,
        };
        PROPS.forEach((prop) => {
          ret[prop] = sumBy(values, prop);
        });
        cumulativeByTs[timestamp] = cumulative;
        return ret;
      })
      .value()
      .filter((item) => item.timestamp >= from);
  }, [feesData]);

  let ret = fillPeriodsWithDefaults(feesChartData, 86400, from, to, "timestamp", { margin: 0, liquidation: 0, swap: 0, mint: 0, burn: 0, marginAndLiquidation: 0 })

  return [ret, loading, error];
}

export function useAumPerformanceData({ from = FROM_DATE_TS, to = NOW_TS, groupPeriod }) {
  const [feesData, feesLoading] = useFeesData({ from, to, groupPeriod });
  const [qlpData, qlpLoading] = useQlpData({ from, to, groupPeriod });
  const [volumeData, ,volumeLoading] = useVolumeData({ from, to, groupPeriod });

  const dailyCoef = 86400 / groupPeriod;

  const data = useMemo(() => {
    if (!feesData || !qlpData || !volumeData) {
      return null;
    }

    const ret = feesData.map((feeItem, i) => {
      const qlpItem = qlpData[i];
      const volumeItem = volumeData[i];
      let apr = feeItem?.all && qlpItem?.aum ? (feeItem.all / qlpItem.aum) * 100 * 365 * dailyCoef : null;
      if (apr > 10000) {
        apr = null;
      }
      let usage = volumeItem?.all && qlpItem?.aum ? (volumeItem.all / qlpItem.aum) * 100 * dailyCoef : null;
      if (usage > 10000) {
        usage = null;
      }

      return {
        timestamp: feeItem.timestamp,
        apr,
        usage,
      };
    });
    const averageApr = ret.reduce((memo, item) => item.apr + memo, 0) / ret.length;
    ret.forEach((item) => (item.averageApr = averageApr));
    const averageUsage = ret.reduce((memo, item) => item.usage + memo, 0) / ret.length;
    ret.forEach((item) => (item.averageUsage = averageUsage));
    return ret;
  }, [feesData, qlpData, volumeData]);

  return [data, feesLoading || qlpLoading || volumeLoading];
}

export function useQlpData({ from = FROM_DATE_TS, to = NOW_TS, chainName = "polygon_zkevm" } = {}) {
  const query = `{
    qlpStats: qlpStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: {period: daily, timestamp_gte: ${from}, timestamp_lte: ${to}}
    ) {
      timestamp
      aumInUsdq: aumInUsdq
      qlpSupply: qlpSupply
      distributedUsd
      distributedEth
    }
  }`;
  let [data, loading, error] = useGraph(query, { chainName, subgraphUrl });

  let cumulativeDistributedUsdPerQlp = 0;
  let cumulativeDistributedEthPerQlp = 0;
  const qlpChartData = useMemo(() => {
    if (!data || (data && data.qlpStats.length === 0)) {
      return null;
    }

    let prevQlpSupply;
    let prevAum;

    let ret = sortBy(data.qlpStats, (item) => item.timestamp)
      .filter((item) => item.timestamp % 86400 === 0)
      .reduce((memo, item) => {
        const last = memo[memo.length - 1];

        const aum = Number(item.aumInUsdq) / 1e18;
        const qlpSupply = Number(item.qlpSupply) / 1e18;

        const distributedUsd = Number(item.distributedUsd) / 1e30;
        const distributedUsdPerQlp = distributedUsd / qlpSupply || 0;
        cumulativeDistributedUsdPerQlp += distributedUsdPerQlp;

        const distributedEth = Number(item.distributedEth) / 1e18;
        const distributedEthPerQlp = distributedEth / qlpSupply || 0;
        cumulativeDistributedEthPerQlp += distributedEthPerQlp;

        const qlpPrice = aum / qlpSupply;
        const timestamp = parseInt(item.timestamp);

        const newItem = {
          timestamp,
          aum,
          qlpSupply,
          qlpPrice,
          cumulativeDistributedEthPerQlp,
          cumulativeDistributedUsdPerQlp,
          distributedUsdPerQlp,
          distributedEthPerQlp,
        };

        if (last && last.timestamp === timestamp) {
          memo[memo.length - 1] = newItem;
        } else {
          memo.push(newItem);
        }

        return memo;
      }, [])
      .map((item) => {
        let { qlpSupply, aum } = item;
        if (!qlpSupply) {
          qlpSupply = prevQlpSupply;
        }
        if (!aum) {
          aum = prevAum;
        }
        item.qlpSupplyChange = prevQlpSupply ? ((qlpSupply - prevQlpSupply) / prevQlpSupply) * 100 : 0;
        if (item.qlpSupplyChange > 1000) item.qlpSupplyChange = 0;
        item.aumChange = prevAum ? ((aum - prevAum) / prevAum) * 100 : 0;
        if (item.aumChange > 1000) item.aumChange = 0;
        prevQlpSupply = qlpSupply;
        prevAum = aum;
        return item;
      });

    ret = fillNa(ret);
    ret = fillPeriodsWithDefaults(ret, 86400, from, to, "timestamp", {})
    return ret;
  }, [data]);

  return [qlpChartData, loading, error];
}

export function useQlpPerformanceData(qlpData, feesData, { from = FROM_DATE_TS } = {}) {
  const [btcPrices] = useCoingeckoPrices("BTC", { from });
  const [ethPrices] = useCoingeckoPrices("ETH", { from });
  //const [maticPrices] = useCoingeckoPrices("MATIC", { from });

  const qlpPerformanceChartData = useMemo(() => {
    if (!btcPrices || !ethPrices || !qlpData || !feesData) {
      return null;
    }

    const qlpDataById = qlpData.reduce((memo, item) => {
      memo[item.timestamp] = item;
      return memo;
    }, {});

    const feesDataById = feesData.reduce((memo, item) => {
      memo[item.timestamp] = item;
      return memo;
    });

    let BTC_WEIGHT = 0.25;
    let ETH_WEIGHT = 0.25;
    //let MATIC_WEIGHT = 0.08;

    let prevEthPrice = 1200;
    //let prevMaticPrice = 0.6;

    const STABLE_WEIGHT = 0.5;
    const QLP_START_PRICE = qlpDataById[btcPrices[0].timestamp]?.qlpPrice || 0.9;

    const btcFirstPrice = btcPrices[0]?.value;
    const ethFirstPrice = ethPrices[0]?.value;
    //const maticFirstPrice = ( maticPrices && maticPrices[0] && maticPrices[0].value ) || prevMaticPrice;

    const indexBtcCount = (QLP_START_PRICE * BTC_WEIGHT) / btcFirstPrice;
    const indexEthCount = (QLP_START_PRICE * ETH_WEIGHT) / ethFirstPrice;
    //const indexMaticCount = (QLP_START_PRICE * MATIC_WEIGHT) / maticFirstPrice;

    const lpBtcCount = (QLP_START_PRICE * 0.5) / btcFirstPrice;
    const lpEthCount = (QLP_START_PRICE * 0.5) / ethFirstPrice;
    //const lpMaticCount = (QLP_START_PRICE * 0.5) / maticFirstPrice;

    const ret = [];
    let cumulativeFeesPerQlp = 0;
    let lastQlpPrice = 0;

    for (let i = 0; i < btcPrices.length; i++) {
      const btcPrice = btcPrices[i].value;
      const ethPrice = ethPrices[i]?.value || prevEthPrice;
      //const maticPrice = ( maticPrices && maticPrices[i] && maticPrices[i].value ) || prevMaticPrice;
      //prevMaticPrice = maticPrice;
      prevEthPrice = ethPrice;

      const timestampGroup = parseInt(btcPrices[i].timestamp / 86400) * 86400;
      const qlpItem = qlpDataById[timestampGroup];
      const qlpPrice = qlpItem?.qlpPrice ?? lastQlpPrice;
      lastQlpPrice = qlpPrice;
      const qlpSupply = qlpDataById[timestampGroup]?.qlpSupply;
      const dailyFees = feesDataById[timestampGroup]?.all;

      const syntheticPrice =
        indexBtcCount * btcPrice +
        indexEthCount * ethPrice +
        //indexMaticCount * maticPrice +
        QLP_START_PRICE * STABLE_WEIGHT;

      const lpBtcPrice =
        (lpBtcCount * btcPrice + QLP_START_PRICE / 2) * (1 + getImpermanentLoss(btcPrice / btcFirstPrice));
      const lpEthPrice =
        (lpEthCount * ethPrice + QLP_START_PRICE / 2) * (1 + getImpermanentLoss(ethPrice / ethFirstPrice));
      // const lpMaticPrice =
      //   (lpMaticCount * maticPrice + QLP_START_PRICE / 2) *
      //   (1 + getImpermanentLoss(maticPrice / maticFirstPrice));

      if (dailyFees && qlpSupply) {
        const QLP_REWARDS_SHARE = 0.7;
        const collectedFeesPerQlp = (dailyFees / qlpSupply) * QLP_REWARDS_SHARE;
        cumulativeFeesPerQlp += collectedFeesPerQlp;

      }

      let qlpPlusFees = qlpPrice;
      if (qlpPrice && qlpSupply && cumulativeFeesPerQlp) {
        qlpPlusFees = qlpPrice + cumulativeFeesPerQlp;
      }

      let qlpApr;
      let qlpPlusDistributedUsd;
      let qlpPlusDistributedEth;
      if (qlpItem) {
        if (qlpItem.cumulativeDistributedUsdPerQlp) {
          qlpPlusDistributedUsd = qlpPrice + qlpItem.cumulativeDistributedUsdPerQlp;
          // qlpApr = qlpItem.distributedUsdPerQlp / qlpPrice * 365 * 100 // incorrect?
        }
        if (qlpItem.cumulativeDistributedEthPerQlp) {
          qlpPlusDistributedEth = qlpPrice + qlpItem.cumulativeDistributedEthPerQlp * ethPrice;
        }
      }

      ret.push({
        timestamp: btcPrices[i].timestamp,
        syntheticPrice,
        lpBtcPrice,
        lpEthPrice,
        //lpMaticPrice,
        qlpPrice,
        btcPrice,
        ethPrice,
        qlpPlusFees,
        qlpPlusDistributedUsd,
        qlpPlusDistributedEth,

        performanceLpEth: ((qlpPrice / lpEthPrice) * 100).toFixed(1),
        performanceLpEthCollectedFees: ((qlpPlusFees / lpEthPrice) * 100).toFixed(1),
        performanceLpEthDistributedUsd: ((qlpPlusDistributedUsd / lpEthPrice) * 100).toFixed(1),
        performanceLpEthDistributedEth: ((qlpPlusDistributedEth / lpEthPrice) * 100).toFixed(1),

        performanceLpBtcCollectedFees: ((qlpPlusFees / lpBtcPrice) * 100).toFixed(1),

        performanceSynthetic: ((qlpPrice / syntheticPrice) * 100).toFixed(1),
        performanceSyntheticCollectedFees: ((qlpPlusFees / syntheticPrice) * 100).toFixed(1),
        performanceSyntheticDistributedUsd: ((qlpPlusDistributedUsd / syntheticPrice) * 100).toFixed(1),
        performanceSyntheticDistributedEth: ((qlpPlusDistributedEth / syntheticPrice) * 100).toFixed(1),

        qlpApr,
      });
    }

    return ret;
  }, [btcPrices, ethPrices, qlpData, feesData]);

  return [qlpPerformanceChartData];
}

export function useTokenStats({ from = FROM_DATE_TS, to = NOW_TS, period = "daily", chainName = "polygon_zkevm" } = {}) {
  const getTokenStatsFragment = ({ skip = 0 } = {}) => `
    tokenStats(
      first: 1000,
      skip: ${skip},
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: ${period}, timestamp_gte: ${from}, timestamp_lte: ${to} }
    ) {
      poolAmountUsd
      timestamp
      token
    }
  `;

  // Request more than 1000 records to retrieve maximum stats for period
  const query = `{
    a: ${getTokenStatsFragment()}
    b: ${getTokenStatsFragment({ skip: 1000 })},
    c: ${getTokenStatsFragment({ skip: 2000 })},
    d: ${getTokenStatsFragment({ skip: 3000 })},
    e: ${getTokenStatsFragment({ skip: 4000 })},
    f: ${getTokenStatsFragment({ skip: 5000 })},
  }`;

  const [graphData, loading, error] = useGraph(query, { chainName, subgraphUrl });

  const data = useMemo(() => {
    if (loading || !graphData) {
      return null;
    }

    const fullData = Object.values(graphData).reduce((memo, records) => {
      memo.push(...records);
      return memo;
    }, []);

    const retrievedTokens = new Set();

    const timestampGroups = fullData.reduce((memo, item) => {
      const { timestamp, token, poolAmountUsd } = item;

      const symbol = tokenSymbols[token] || token;

      retrievedTokens.add(symbol);

      memo[timestamp] = memo[timestamp || 0] || {};

      memo[timestamp][symbol] = {
        poolAmountUsd: parseInt(poolAmountUsd) / 1e30,
      };

      return memo;
    }, {});

    const poolAmountUsdRecords = [];

    Object.entries(timestampGroups).forEach(([timestamp, dataItem]) => {
      const nextTimestamp = Number(timestamp) + 86400;

      Object.entries(dataItem).forEach(([token, stats]) => {
        if (timestampGroups[nextTimestamp] && !timestampGroups[nextTimestamp][token]) {
          timestampGroups[nextTimestamp][token] = { poolAmountUsd: stats.poolAmountUsd };
        }
      });
    });

    Object.entries(timestampGroups).forEach(([timestamp, dataItem]) => {
      const poolAmountUsdRecord = Object.entries(dataItem).reduce(
        (memo, [token, stats]) => {
          memo.all += stats.poolAmountUsd;
          memo[token] = stats.poolAmountUsd;
          memo.timestamp = timestamp;

          return memo;
        },
        { all: 0 }
      );

      poolAmountUsdRecords.push(poolAmountUsdRecord);
    });

    return {
      poolAmountUsd: poolAmountUsdRecords,
      tokenSymbols: Array.from(retrievedTokens),
    };
  }, [graphData, loading]);

  return [data, loading, error];
}

export function useReferralsData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "polygon_zkevm" } = {}) {
  const query = `{
    globalStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
    ) {
      volume
      volumeCumulative
      totalRebateUsd
      totalRebateUsdCumulative
      discountUsd
      discountUsdCumulative
      referrersCount
      referrersCountCumulative
      referralCodesCount
      referralCodesCountCumulative
      referralsCount
      referralsCountCumulative
      timestamp
    }
  }`;

  const [graphData, loading, error] = useGraph(query, { subgraphUrl: referralSubgraphUrl });

  const data = graphData
    ? sortBy(graphData.globalStats, "timestamp").map((item) => {
        const totalRebateUsd = item.totalRebateUsd / 1e30;
        const discountUsd = item.discountUsd / 1e30;
        return {
          ...item,
          volume: item.volume / 1e30,
          volumeCumulative: item.volumeCumulative / 1e30,
          totalRebateUsd,
          totalRebateUsdCumulative: item.totalRebateUsdCumulative / 1e30,
          discountUsd,
          referrerRebateUsd: totalRebateUsd - discountUsd,
          discountUsdCumulative: item.discountUsdCumulative / 1e30,
          referralCodesCount: parseInt(item.referralCodesCount),
          referralCodesCountCumulative: parseInt(item.referralCodesCountCumulative),
          referrersCount: parseInt(item.referrersCount),
          referrersCountCumulative: parseInt(item.referrersCountCumulative),
          referralsCount: parseInt(item.referralsCount),
          referralsCountCumulative: parseInt(item.referralsCountCumulative),
        };
      })
    : null;

  return [data, loading, error];
}
