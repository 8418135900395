
export const POLYGON_ZKEVM = 1101;

export const addresses = {

  [POLYGON_ZKEVM]: {
    Vault: "0x99b31498b0a1dae01fc3433e3cb60f095340935c",
    Router: "0x1fe9fba5955af58c18057213f0151bbe893ab2c8",
    VaultReader: "0x8a8eafb33011e912952f484e1640f9571de7c163",
    Reader: "0xf1cfb75854de535475b88bb6fbad317eea98c0f9",
    QlpManager: "0x87bcd3914ed3dcd5886ba1c0f0da25150b56fe54",
    RewardRouter: "0x527d5cffd31a21f1c09c4cebf440e218a9fd8683",
    RewardReader: "0x5f24aa47cd5e9d5bbfdd693f6efc661c5a6fc7da",
    QLP: "0xc8e48fd037d1c4232f294b635e74d33a0573265a",
    USDQ: "0x48ac594dd00c4aacf40f83337fc6da31f9f439a7",
    StakedQlpTracker: "0x42d36ba59e1d3dcc96365948df794e0054e5fd4d",
    FeeQlpTracker: "0x4f9e9a2edc0471b6d52634995f827b6678bfb4bd",
    StakedQlpDistributor: "0xdb1b5d7622b7e6a9e4fbd7658d1d5994174dcdcc",
    OrderBook: "0x7e01238227213c513010f5fabd0634febee93ee5",
    OrderExecutor: "0x0cdda294315412b1ba25aee84edd1d2bb67a0076",
    OrderBookReader: "0x0cdda294315412b1ba25aee84edd1d2bb67a0076",
    PositionRouter: "0x443cf165b72e4b4331c0101a10553269972ed4b8",
    PositionManager: "0x2264d1c908c4347f75f8eb438a4f94f4caecf634",
    ReferralStorage: "0xd0357bae72a794a92c321c78a40a106d503527be",
    ReferralReader: "0x29b3e948ce2db1964006ea9f5ea072ce7d008a63",
    FastPriceFeed: "0x73903fec691a80ec47bc830bf3f0bad127a06e30",
    MATIC: "0xa2036f0538221a77a3937f1379699f44945018d0",
    BTC: "0xea034fb02eb1808c2cc3adbc15f447b93cbe08e1",
    ETH: "0x4f9a0e7fd2bf6067db6994cf12e4495df938e6e9",
    USDC: "0xa8ce8aee21bc2a48a5ef670afcc9274c7bbbc035",
    USDT: "0x1e4a5963abfd975d8c9021ce480b42188849d41d",
    DAI: "0xc5015b9d9161dca7e18e32f6f25c4ad850731fd4"

  },
};

export const botAddresses = [];

export const botActiveAddresses = [];

export const botAccountAddresses = [];


export function getAddress(chainId, key) {
  if (!chainId in addresses) {
    throw new Error(`Unknown chain ${chainId}`);
  }
  if (!(key in addresses[chainId])) {
    throw new Error(`Unknown address key ${key}`);
  }
  return addresses[chainId][key];
}